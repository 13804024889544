import React, { useEffect } from "react";
import Header from "../header";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SettingWrapper } from "./style";

export default function Setting() {
  const navigate = useNavigate();

  const tools = [
    {
      icon: <CloseOutlined></CloseOutlined>,
      onClick: () => {
        navigate(-1);
      },
    },
  ];
  const message = "hello";

  return (
    <Header title="设置" msg="所有设置选项" tools={tools}>
      <SettingWrapper></SettingWrapper>
    </Header>
  );
}
