import { createSlice } from "@reduxjs/toolkit";
const lightMode = {
  cardBorderShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
  asideColor: "#e7f8ff",
  fontColor: "#303030",
  backgroudColor: "white",
  lightFontColor: "#a6a6a6",
  hoverColor: "#efefef",
  borderColor: "#1d93ab",
  lightBorderColor: "rgba(29,147,171,0.5)",
  bottomBorderColor: "1px solid rgba(0, 0, 0, 0.2)",
};
const darkMode = {
  cardBorderShadow: "0 2px 5px rgba(256, 256, 256, 0.05)",
  asideColor: "#1b262a",
  fontColor: "#bbb",
  backgroudColor: "#1e1e1e",
  lightFontColor: "#a6a6a6",
  hoverColor: "rgba(256,256,256,0.2)",
  borderColor: "#1d93ab",
  lightBorderColor: "rgba(29,147,171,0.5)",
  bottomBorderColor: "1px solid rgba(256, 256, 256, 0.2)",
};
const NotFullScreen = {
  width: "90vw",
  height: "90vh",
  maxWidth: "1200px",
};
const fullScreen = {
  width: "100vw",
  height: "100vh",
  maxWidth: "100vw",
};
const font = {
  size: "14px",
};
const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chats: [],
    chat: {
      nums: 0,
      chatId: 0,
      title: "",
      updateTime: "",
    },
    screen: {
      ...NotFullScreen,
      full: false,
    },
    theme: {
      ...lightMode,
      light: true,
    },
    font,
  },
  reducers: {
    setChat(state, action) {
      state.chat = action.payload;
    },
    setFullScreen(state, action) {
      if (action.payload) {
        state.screen = {
          ...fullScreen,
          full: true,
        };
      } else {
        state.screen = state.screen = {
          ...NotFullScreen,
          full: false,
        };
      }
    },
    setLightTheme(state, action) {
      if (action.payload) {
        state.theme = {
          ...lightMode,
          light: true,
        };
      } else {
        state.theme = {
          ...darkMode,
          light: false,
        };
      }
    },
    setFont(state, action) {
      state.font = {
        ...state.font,
        ...action.payload,
      };
    },
    setChats(state, action) {
      state.chats = action.payload;
    },
  },
});
export default chatSlice.reducer;
export const { setChat, setFullScreen, setLightTheme, setFont, setChats } =
  chatSlice.actions;
