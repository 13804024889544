import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
// rgb(217,243,255)
export const AppWrapper = styled.div`
  background-color: ${(p) => p.theme.backgroudColor};
  width: 100vw;
  user-select: none;
  overflow: hidden;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.fontColor};

  .container {
    background-color: ${(p) => p.theme.backgroudColor};
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
    min-width: 600px;
    min-height: 370px;
    max-width: 1200px;
    overflow: hidden;
    border-radius: 20px;
    color: ${(p) => p.theme.fontColor};
    transition: all 0.3s linear;
    display: flex;
  }
`;
export const AsideWrapper = styled.div`
  top: 0;
  width: 316px;
  padding: 20px;
  background-color: ${(p) => p.theme.asideColor};
  display: flex;
  flex-direction: column;
  transition: all 0.2 linear;
  box-shadow: ${(p) => p.theme.cardBorderShadow};
  color: ${(p) => p.theme.fontColor};
  animation: alternate;
  .card :nth-last-child() {
    margin-top: 40px;
    opacity: 0;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }
  .header {
    padding: 20px 0px;
    position: relative;
  }
  .logo {
    width: 120px;
    position: absolute;
    right: 0;
    top: 10px;
    opacity: 0.3;
    clip-path: circle(30px at 60px 36px);
    transition: all 0.3s linear;
  }
  .logo:hover {
    opacity: 0.5;
  }
  .body {
    flex: 1 1;
    display: block;
    margin-top: 10px;
    overflow-x: hidden;
    overflow: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: ${(p) => p.theme.borderColor};
    }

    /*定义滚动条轨道

内阴影+圆角*/

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

      border-radius: 10px;

      background-color: ${(p) => p.theme.backgroudColor};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

      background-color: ${(p) => p.theme.lightBorderColor};
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .footer .tool-card {
    display: block;
    padding: 10px 12px;
    border-radius: 10px;
    background-color: ${(p) => p.theme.backgroudColor};
    box-shadow: ${(p) => p.theme.cardBorderShadow};
    transition: all 0.2s linear;
    color: ${(p) => p.theme.fontColor};
    .button-title {
      font-size: 14px;
    }
  }
  .footer .tool-card:hover {
    background-color: ${(p) => p.theme.hoverColor};
  }
  .footer .tool-card .icon {
    transform: rotate(0deg);
    transition: all 0.3s;
  }
  .footer .tool-card:active {
    .icon {
      transition: all 0s;
      transform: rotate(90deg);
    }
  }
  .active-card {
    border: 1px ${(p) => p.theme.borderColor} solid;
  }
  .add {
    margin-right: 10px;
  }
`;
export const ChatCardWrapper = styled(NavLink)`
  position: relative;
  display: block;
  background-color: ${(p) => p.theme.backgroudColor};
  box-shadow: ${(p) => p.theme.cardBorderShadow};
  padding: 10px 14px;
  border-radius: 10px;
  margin: 0px 10px;
  margin-top: 10px;
  transition: all 0.5s ease;
  color: ${(p) => p.theme.fontColor};
  user-select: none;

  .title {
    font-size: 17px;
  }
  .msg {
    font-size: 12px;
    color: ${(p) => p.theme.lightFontColor};
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    transition: all 0.3s ease;
  }
  &:hover {
    background-color: ${(p) => p.theme.hoverColor};
    color: ${(p) => p.theme.fontColor};
  }
  &:hover .delete {
    display: block;
    animation: move-left 0.25s linear;
  }
  @keyframes move-left {
    from {
      right: 0px;
      top: 0px;
    }
    to {
      top: 5px;
      right: 5px;
    }
  }
  .delete {
    display: none;
    right: 5px;
    top: 5px;
    font-size: 16px;
    position: absolute;
    transition: all 0.3s linear;
  }
`;

export const BodyWrapper = styled.div`
  width: calc(100% - 350px);
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.backgroudColor};
`;
