const USER_INFO_KEY = "userInfo";
export const setUser = (userInfo) => [
  localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo)),
];
export const getUser = () => {
  return JSON.parse(localStorage.getItem(USER_INFO_KEY));
};
export const removeUser = () => {
  localStorage.removeItem(USER_INFO_KEY);
};
