import axios from "axios";
import { publish } from "pubsub-js";
import { getToken } from "../localStorage/token";

// 创建 axios 实例

const service = axios.create({
  timeout: 50000,
  baseURL: process.env.REACT_APP_API_BASEURL,
  withCredentials: true,
});

// request 拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["satoken"] = getToken();
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const { data } = response;
    const { errorMsg, code } = data;
    // 例如，可以在这里判断请求是否成功
    if (code === 401) {
      publish("auth");
      publish("error", errorMsg);
    } else {
      if (code === 500) {
        publish("error", errorMsg);
      } else {
        return data;
      }
    }
  },
  (error) => {
    // 对响应错误做点什么
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

export default service;

export const connectSocket = (url) => {
  return new WebSocket(process.env.REACT_APP_SOCKET_BASEURL + url);
};
