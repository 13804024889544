import { styled } from "styled-components";

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  .login-card,
  .registe-card {
    position: relative;
    border: ${(p) => p.theme.asideColor} 1px solid;
    box-shadow: ${(p) => p.theme.cardBorderShadow};
    padding: 30px 30px 0px;
    border-radius: 20px;
  }
  .registe-card {
    display: none;
    transform: rotateY(270deg);
  }
`;

export const LoadingWrapper = styled.div`
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  backdrop-filter: blur(10px);
  background: ${(p) => p.theme.backgroudColor};
`;
