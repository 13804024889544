import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "./chatReducer";
import robotReducer from "./robotReducer";

export default configureStore({
  reducer: {
    chat: chatReducer,
    robot: robotReducer,
  },
});
