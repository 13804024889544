import service from "../service";

export const getRobotList = () => {
  return service.get("/user/getUserRobot");
};

export const addUser = (params) => {
  return service.get("/user/register", {
    params,
  });
};

export const loginUser = (params) => {
  return service.get("/user/login", {
    params,
  });
};
