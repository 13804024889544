import { createBrowserRouter } from "react-router-dom";
import ChatRoom from "../components/chat-room";
import Chat from "../components/chat";
import Setting from "../components/setting";
import Login from "../components/login";
import Blank from "../components/blank";

const routes = [
  {
    path: "/",
    element: <ChatRoom></ChatRoom>,
    children: [
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "setting",
        element: <Setting></Setting>,
      },
      {
        path: "login",
        element: <Login></Login>,
      },
      {
        path: "blank",
        element: <Blank></Blank>,
      },
    ],
  },
];

export default createBrowserRouter(routes);
