import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  AppWrapper,
  AsideWrapper,
  BodyWrapper,
  ChatCardWrapper,
} from "./style";
import Logo from "../../assets/logo.jpg";
import dayjs from "dayjs";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { setChat, setChats } from "../../store/chatReducer";
import { setRobots } from "../../store/robotReducer";
import { ThemeProvider } from "styled-components";
import { Button, ConfigProvider, Form, Input, Modal, Select, Spin } from "antd";
import { useAnimation, useSub, useRobot } from "./useUserInfo";
import { LoadingWrapper } from "../style";
import { deleteChatByChatId, getChats, postChat } from "../../api/chat";
import { postRobot } from "../../api/robot";
import Blank from "../blank";
import { useNavigate } from "react-router-dom";

function ChatRoom(props) {
  const { chats } = props;
  const { width, height, maxWidth } = props.screen;
  console.log(width, height);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [value, setValue] = useState(0);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const [openRobotModal, setOpenRobotModal] = useState(false);

  const [robotOption, getRobots] = useRobot(
    props.setRobots,
    props.robots,
    setValue,
    setLoading
  );
  useEffect(() => {
    if (!props.chat) {
      navigate("/blank");
    } else {
      navigate("/chat");
    }
  }, [props.chat]);
  useEffect(() => {
    if (value) {
      setLoading(true);
      getChats(value).then((res) => {
        if (res && res.code == 200) {
          props.setChats(res.data);
        }
      });
      setLoading(false);
    }
  }, [value]);

  useEffect(() => {
    if (chats.length > 0) {
      props.setChat(chats[currentIndex]);
    }
  }, [chats]);

  const [robotForm, SetRobotForm] = useState({
    id: "",
  });
  const resetRobotForm = () => {
    SetRobotForm({
      title: "",
      id: "",
    });
  };

  useAnimation(chats);
  const [contextHolder, messageApi] = useSub();
  const deleteChat = async (chatId) => {
    setLoading(true);
    await deleteChatByChatId(chatId);
    props.setChats(chats.filter((chat) => chat.chatId !== chatId));
    messageApi.success("删除成功");
    setLoading(false);
  };
  const addChat = async () => {
    if (!title.trim()) {
      messageApi.error("标题不能为空");
      return;
    }
    setLoading(true);
    await postChat(title, value);
    const res = await getChats(value);
    if (res && res.code == 200) {
      props.setChats(res.data);
    }
    setLoading(false);
    setOpen(false);
    setTitle("");
  };
  const addRobot = async () => {
    if (!robotForm.id.trim()) {
      messageApi.error("机器人名称不能为空");
      return;
    }
    setLoading(true);
    await postRobot({
      uuid: robotForm.id,
    });
    getRobots();
    setLoading(false);
    setOpen(false);
    resetRobotForm();
  };

  return (
    <AppWrapper>
      <ThemeProvider theme={props.theme}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: props.theme.borderColor,
            },
          }}
        >
          <Modal
            open={open}
            title="新增聊天"
            onCancel={() => {
              setOpen(false);
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setOpen(false);
                  setTitle("");
                }}
              >
                返回
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => {
                  addChat();
                }}
              >
                提交
              </Button>,
            ]}
          >
            <Input
              placeholder="请输入聊天标题"
              style={{ marginTop: "30px" }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  addChat();
                }
              }}
            />
          </Modal>
          <Modal
            open={openRobotModal}
            title="绑定机器人"
            onOk={() => {}}
            onCancel={() => {
              setOpenRobotModal(false);
              resetRobotForm();
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setOpenRobotModal(false);
                  resetRobotForm();
                }}
              >
                返回
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={addRobot}
              >
                提交
              </Button>,
            ]}
          >
            <Input
              placeholder="请输入机器人id"
              style={{ marginTop: "30px" }}
              value={robotForm.id}
              onChange={(e) => {
                SetRobotForm({
                  ...robotForm,
                  id: e.target.value,
                });
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  addRobot();
                }
              }}
            />
          </Modal>
          {contextHolder}
          <div className="container" style={{ width, height, maxWidth }}>
            {loading ? (
              <LoadingWrapper>
                <Spin></Spin>
              </LoadingWrapper>
            ) : (
              ""
            )}
            <AsideWrapper>
              <div className="header">
                <div className="title">机器人聊天</div>
                <div className="title-English">robot-chat</div>
                <img
                  src={Logo}
                  alt="机器人logo"
                  className="logo"
                  onClick={() => {
                    setOpenRobotModal(true);
                  }}
                />
              </div>
              <div className="body">
                {chats?.map((chat, index) => {
                  return (
                    <ChatCardWrapper
                      key={chat.chatId}
                      to="/chat"
                      className={
                        currentIndex == index ? "active-card card" : "card"
                      }
                      onClick={() => {
                        props.setChat(chat);
                        setCurrentIndex(index);
                      }}
                    >
                      <div className="title">{chat.title}</div>
                      <div className="msg">
                        <div>{chat.nums}条对话</div>
                        <div>
                          {dayjs(new Date(...chat.updateTime)).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                        <CloseCircleOutlined
                          className="delete"
                          onClick={() => {
                            deleteChat(chat.chatId);
                          }}
                        />
                      </div>
                    </ChatCardWrapper>
                  );
                })}
              </div>
              <div className="footer">
                {/* <NavLink to="/setting" className="tool-card">
                <SettingOutlined className="icon" />
              </NavLink> */}
                <Select
                  placeholder="选择机器人"
                  options={robotOption}
                  value={value}
                  onChange={(e) => {
                    setValue(e);
                  }}
                ></Select>
                <div className="tool-card">
                  <PlusCircleOutlined className="add" />
                  <span
                    className="button-title"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    新增聊天
                  </span>
                </div>
              </div>
            </AsideWrapper>
            <BodyWrapper>
              <Outlet></Outlet>
            </BodyWrapper>
          </div>
        </ConfigProvider>
      </ThemeProvider>
    </AppWrapper>
  );
}
export default connect(
  (state) => {
    return {
      ...state.chat,
      ...state.robot,
    };
  },
  {
    setChat,
    setRobots,
    setChats,
  }
)(ChatRoom);
