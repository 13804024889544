import React from "react";
import { BlankWrapper } from "./style";

export default function Blank() {
  return (
    <BlankWrapper>
      <div className="blank-container">
        <div className="title">欢迎使用小A机器人</div>
        <div className="title cover">欢迎使用小A机器人</div>
      </div>
    </BlankWrapper>
  );
}
