import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getChatRecords } from "../../api/chat";
import { useDebounce } from "../../utils/useDebounce";
import { connectSocket } from "../../service";
import { subscribe } from "pubsub-js";

export const useChatRecord = (
  chatId,
  setLoading,
  setInputValue,
  inputValue,
  messageApi
) => {
  let [chatRecords, setChatRecords] = useState([]);
  let [webSocket, setWebSocket] = useState(null);
  const [lastMessage, setLastMessage] = useState("");

  const connectWebSocket = async (chatId) => {
    new Promise((resolve) => {
      const socket = connectSocket(`/ws/${chatId}`);
      socket.onerror = (e) => {
        console.log("连接错误");
      };
      socket.onmessage = (e) => {
        setLastMessage(e.data);
      };
      socket.onclose = () => {
        console.log("连接关闭");
        setWebSocket(null);
      };
      socket.onopen = () => {
        console.log("连接成功");
        setWebSocket(socket);
        resolve();
      };
    });
  };

  const debounceGetChatRecords = useCallback(
    useDebounce((chatId, webSocket) => {
      getChatRecords(chatId).then(async (res) => {
        if (res && res.code == 200) {
          setChatRecords(res.data);
          if (webSocket) webSocket.close();
          await connectWebSocket(chatId);
          setLoading(false);
        }
      });
    }, 1000),
    []
  );

  const send = async () => {
    setLoading(true);
    if (!inputValue) {
      messageApi.warning("无法输入空串");
      return;
    }
    if (!webSocket) {
      await connectWebSocket();
    }
    const value = inputValue;
    setChatRecords([...chatRecords, value]);
    setInputValue("");
    webSocket.send(value);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    debounceGetChatRecords(chatId, webSocket);
  }, [chatId]);

  const bodyRef = useRef();
  useEffect(() => {
    bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
  }, [chatRecords, inputValue]);

  useEffect(() => {
    setChatRecords([...chatRecords, lastMessage]);
  }, [lastMessage]);
  return [chatRecords, send, bodyRef];
};
