import React, { useState } from "react";
import Header from "../header";
import { Button, Checkbox, ConfigProvider, Form, Input, Spin } from "antd";
import { LoginWrapper, RegisteWrapper } from "./style";
import { LoadingWrapper } from "../style";
import { connect } from "react-redux";
import { useAnimation, useForm } from "./useLogin";
import { getUser } from "../../localStorage/user";
import { setRobots } from "../../store/robotReducer";
import { setChats, setChat } from "../../store/chatReducer";
function Login(props) {
  const [title, setTitle] = useState("登录");
  const [registeAnimation, loginAnimation] = useAnimation();
  const [loading, setLoading] = useState(false);
  const [registe, login] = useForm(
    () => {
      loginAnimation();
      setTitle("注册");
    },
    setLoading,
    props.setRobots,
    props.setChats,
    props.setChat
  );

  return (
    <Header title={title}>
      <LoginWrapper>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: props.theme.borderColor,
            },
          }}
        >
          <div className="login-card">
            {loading ? (
              <LoadingWrapper>
                <Spin></Spin>
              </LoadingWrapper>
            ) : (
              ""
            )}
            <Form
              name="login"
              onFinish={login}
              initialValues={getUser()}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 18,
              }}
              style={{
                maxWidth: 700,
              }}
              autoComplete="off"
              labelAlign="right"
            >
              <Form.Item
                label="用户名"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "请输入用户名",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                  offset: 8,
                }}
              >
                <Checkbox>记住</Checkbox>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  登录
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setTitle("注册");
                    registeAnimation();
                  }}
                >
                  注册
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="registe-card">
            {loading ? (
              <LoadingWrapper>
                <Spin></Spin>
              </LoadingWrapper>
            ) : (
              ""
            )}
            <Form
              onFinish={registe}
              className=""
              name="registe"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 18,
              }}
              style={{
                maxWidth: 700,
              }}
              autoComplete="off"
              labelAlign="right"
            >
              <Form.Item
                label="用户名"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "请输入用户名",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="确认密码"
                name="okPassword"
                rules={[
                  {
                    required: true,
                    message: "请输入密码",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="sumbit">
                  注册
                </Button>
              </Form.Item>
            </Form>
          </div>
        </ConfigProvider>
      </LoginWrapper>
    </Header>
  );
}
export default connect((state) => state.chat, {
  setRobots,
  setChats,
  setChat,
})(Login);
