const TOKEN_INFO_KEY = "token";
export const setToken = (token) => [
  localStorage.setItem(TOKEN_INFO_KEY, token),
];
export const getToken = () => {
  return localStorage.getItem(TOKEN_INFO_KEY);
};
export const removeToken = () => {
  localStorage.removeItem(TOKEN_INFO_KEY);
};
