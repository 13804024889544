import React, { Fragment } from "react";
import { HeaderWrapper } from "./style.js";

import { connect } from "react-redux";
import { setFullScreen, setLightTheme } from "../../store/chatReducer.js";
import { ThemeProvider } from "styled-components";

function Header(props) {
  const { title, msg, tools } = props;
  return (
    <Fragment>
      <ThemeProvider theme={{ ...props.theme, ...props.font }}>
        <HeaderWrapper className="header">
          <div>
            <div className="title">{title}</div>
            <div className="count">{msg}</div>
          </div>
          {tools?.map((item, index) => {
            return (
              <div
                className="icon-container"
                onClick={item.onClick}
                key={index}
              >
                {item.icon}
              </div>
            );
          })}
        </HeaderWrapper>
        {props.children}
      </ThemeProvider>
    </Fragment>
  );
}
export default connect(
  (state) => {
    return state.chat;
  },
  {
    setFullScreen,
    setLightTheme,
  }
)(Header);
