import { styled } from "styled-components";

export const HeaderWrapper = styled.div`
  background: ${(p) => p.theme.backgroudColor};
  width: 100%;
  padding: 14px 20px;
  position: relative;
  border-bottom: ${(p) => p.theme.bottomBorderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.fontColor};
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .count {
    font-size: 14px;
    margin-top: 5px;
  }
  .icon-container {
    border: ${(p) => p.theme.bottomBorderColor};
    border-radius: 10px;
    margin-right: 30px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    transition: all 0.25s linear;
    color: ${(p) => p.theme.fontColor};
    &:hover {
      background-color: ${(p) => p.theme.hoverColor};
      border: 1px solid ${(p) => p.theme.borderColor};
    }
  }
`;
