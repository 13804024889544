import { createSlice } from "@reduxjs/toolkit";

const robotSlice = createSlice({
  initialState: {
    robots: [],
  },
  name: "robot",
  reducers: {
    setRobots(state, action) {
      state.robots = action.payload;
    },
  },
});
export default robotSlice.reducer;
export const { setRobots } = robotSlice.actions;
