import { keyframes, styled } from "styled-components";

export const HeaderWrapper = styled.div`
  background: ${(p) => p.theme.backgroudColor};
  width: 100%;
  padding: 14px 20px;
  border-bottom: ${(p) => p.theme.bottomBorderColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.fontColor};
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .count {
    font-size: 14px;
    margin-top: 5px;
  }
  .icon-container {
    border: ${(p) => p.theme.bottomBorderColor};
    border-radius: 10px;
    margin-right: 30px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    transition: all 0.25s linear;
    color: ${(p) => p.theme.fontColor};
    &:hover {
      background-color: ${(p) => p.theme.hoverColor};
      border: 1px solid ${(p) => p.theme.borderColor};
    }
  }
`;

export const BodyWrapper = styled.div`
  background: ${(p) => p.theme.backgroudColor};
  flex: 1 1;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${(p) => p.theme.borderColor};
  }

  /*定义滚动条轨道

内阴影+圆角*/

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    background-color: ${(p) => p.theme.backgroudColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background-color: ${(p) => p.theme.lightBorderColor};
  }
  overscroll-behavior: none;
  padding: 20px 20px 40px;
  .left {
    align-items: start;
    margin-top: 20px;
    .content {
      background-color: ${(p) => p.theme.hoverColor};
    }
  }
  .right {
    align-items: end;
    margin-top: 20px;
    .content {
      background-color: ${(p) => p.theme.asideColor};
    }
    &:hover {
      .icon-container::before {
      }
    }
  }
`;

export const FooterWrapper = styled.div`
  color: ${(p) => p.theme.fontColor};
  background: ${(p) => p.theme.backgroudColor};
  width: 100%;
  padding: 10px 20px 10px 10px;
  border-top: 1px solid ${(p) => p.theme.bottomBorderColor};
  box-shadow: ${(p) => p.theme.cardBorderShadow};
  .tool {
    display: flex;
  }
  .input-container {
    cursor: text;
    display: flex;
    align-items: end;
    margin-right: 20px;
    border-radius: 10px;
    border: ${(p) => p.theme.bottomBorderColor};
    transition: all 0.2s linear;
  }
  .input {
    color: ${(p) => p.theme.fontColor};
    background: ${(p) => p.theme.backgroudColor};
    min-height: 68px;
    border-radius: 10px;
    border: none;
    padding: 10px 10px 10px 14px;
    resize: none;
    font-size: 18px;
    outline: none;
    width: 100%;
    height: 100%;
  }
  .input-focus {
    border: 1px solid ${(p) => p.theme.borderColor};
    box-shadow: 0px 0px 5px ${(p) => p.theme.lightBorderColor};
  }
  .send-button {
    background: ${(p) => p.theme.backgroudColor};
    color: ${(p) => p.theme.fontColor};
    margin: 10px;
    opacity: 1;
    &:hover {
      background-color: ${(p) => p.theme.hoverColor} !important;
    }
  }
  .tool {
    padding: 10px 0px;
  }
  .icon-container {
    display: flex;
    border: ${(p) => p.theme.bottomBorderColor};
    border-radius: 10px;
    margin-right: 15px;
    padding: 5px 12px;
    transition: all 0.4s linear;
    .size-slider {
      width: 80px;
      margin: 0px;
      margin-left: 20px;
    }
    .icon::after {
      content: "切换模式";
      font-size: 12px;
      width: 0px;
      transition: all 0.3s linear;
      opacity: 0;
    }
    &:hover {
      background-color: ${(p) => p.theme.hoverColor};
      border: 1px solid ${(p) => p.theme.borderColor};
      .icon::after {
        display: flex;
        width: 100%;
        margin-left: 10px;
        opacity: 1;
      }
    }
  }
  .fresh {
    margin-left: 10px;
  }
`;

export const ChatBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.size};
  .header:hover {
    .tool {
      display: flex;
      animation: outlook linear 0.5s;
    }
    .icon-container {
      display: none;
    }
  }
  .header {
    display: flex;
  }
  .icon-container {
    border: ${(p) => p.theme.bottomBorderColor};
    border-radius: 10px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    transition: all 0.25s linear;
    color: ${(p) => p.theme.fontColor};
    position: relative;
  }
  .tool {
    border: ${(p) => p.theme.bottomBorderColor};
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    display: none;
    color: ${(p) => p.theme.fontColor};
    &:active {
      background-color: ${(p) => p.theme.hoverColor};
    }
  }
  .content {
    margin-top: 10px;
    border: ${(p) => p.theme.bottomBorderColor};
    color: ${(p) => p.theme.fontColor};
    padding: 10px;
    border-radius: 10px;
  }
  @keyframes outlook {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .logo {
    width: 30px;
    transition: all 0.3s linear;
  }
`;
