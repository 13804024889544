import service from "../service";

export const getChats = (robotId) => {
  return service.get("/chat/getChatId", {
    params: {
      robotId,
    },
  });
};
export const getChatRecords = (chatId) => {
  return service.get("/chat/getChat", {
    params: {
      chatId,
    },
  });
};

export const deleteChatByChatId = (chatId) => {
  return service.delete(`/chat/${chatId}`);
};

export const postChat = (title, robotId) => {
  return service.get("/chat/getNewChat", {
    params: {
      title,
      robotId,
    },
  });
};
