import styled from "styled-components";

export const BlankWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    position: relative;
  }
  .blank-container {
    position: relative;
  }
  .title {
    color: ${(p) => p.theme.backgroudColor};
    font-size: 60px;
    text-shadow: ${(p) => p.theme.cardBorderShadow};
  }
  .cover {
    position: absolute;
    left: 0;
    top: 0;
    color: ${(p) => p.theme.borderColor};
    width: 0%;
    overflow: hidden;
    height: 80px;
    animation: expand 2.5s infinite ease-in alternate-reverse;
    border-right: ${(p) => p.theme.borderColor} 1px solid;
  }
  @keyframes expand {
    0% {
      width: 0%;
    }
    100% {
      width: 105%;
    }
  }
`;
