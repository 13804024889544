import React, { Fragment, useEffect, useState } from "react";
import { BodyWrapper, ChatBodyWrapper, FooterWrapper } from "./style.js";
import {
  CopyOutlined,
  FullscreenOutlined,
  SendOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import {
  setFullScreen,
  setLightTheme,
  setFont,
} from "../../store/chatReducer.js";
import { Button, message, Spin } from "antd";
import Logo from "../../assets/logo.jpg";
import Header from "../header/index.jsx";
import { useChatRecord } from "./useChat.js";
import { LoadingWrapper } from "../style.js";

function Chat(props) {
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const tools = [
    {
      icon: <FullscreenOutlined></FullscreenOutlined>,
      onClick: () => {
        props.setFullScreen(!props.screen.full);
      },
    },
  ];
  const { title, nums, chatId } = props.chat ?? {
    title: "",
    nums: 0,
    chatId: 0,
  };
  const [loading, setLoading] = useState(false);
  const [chatRecords, send, bodyRef] = useChatRecord(
    chatId,
    setLoading,
    setInputValue,
    inputValue,
    messageApi
  );

  return (
    <Fragment>
      {contextHolder}
      <Header tools={tools} title={title} msg={`共${nums}条对话`}>
        <BodyWrapper ref={bodyRef}>
          {loading ? (
            <LoadingWrapper>
              <Spin></Spin>
            </LoadingWrapper>
          ) : (
            ""
          )}
          {chatRecords?.map((item, index) => {
            return (
              <ChatBodyWrapper
                key={index}
                className={index % 2 ? "left" : "right"}
              >
                <div className="header">
                  <div className="icon-container">
                    {index % 2 ? (
                      <img src={Logo} alt="机器人logo" className="logo" />
                    ) : (
                      "🐟"
                    )}
                  </div>
                  <div className="tool">
                    <CopyOutlined
                      onClick={() => {
                        navigator.clipboard.writeText(item).then(() => {
                          messageApi.success("复制成功");
                        });
                      }}
                    ></CopyOutlined>
                  </div>
                </div>
                <div className="content">{item}</div>
              </ChatBodyWrapper>
            );
          })}
          <div></div>
          {inputValue.length !== 0 ? (
            <ChatBodyWrapper className="right">
              <div className="header">
                <div className="icon-container">😊</div>
                <div className="tool">
                  <CopyOutlined
                    onClick={() => {
                      navigator.clipboard.writeText(inputValue).then(() => {
                        messageApi.success("复制成功");
                      });
                    }}
                  ></CopyOutlined>
                </div>
              </div>
              <div className="content">{inputValue}</div>
            </ChatBodyWrapper>
          ) : (
            ""
          )}
        </BodyWrapper>
        <FooterWrapper>
          <div className="tool">
            <div className="icon-container">
              <SyncOutlined
                className="icon"
                onClick={() => {
                  props.setLightTheme(!props.theme.light);
                }}
              />
            </div>
          </div>
          <div
            className={
              focus ? "input-container input-focus" : "input-container"
            }
          >
            <textarea
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              placeholder="点击shift+enter发送"
              className="input"
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              value={inputValue}
              onKeyUp={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  send();
                }
              }}
            ></textarea>
            <Button
              icon={<SendOutlined />}
              className="send-button"
              onClick={send}
            >
              发送
            </Button>
          </div>
        </FooterWrapper>
      </Header>
    </Fragment>
  );
}
export default connect(
  (state) => {
    return state.chat;
  },
  {
    setFullScreen,
    setLightTheme,
    setFont,
  }
)(Chat);
