import { useEffect, useState } from "react";
import { getRobotList } from "../../api/user";
import { subscribe, unsubscribe } from "pubsub-js";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

export const useRobot = (setRobots, robots, setValue, setLoading) => {
  const [robotOption, setRobotOption] = useState([]);
  const navigate = useNavigate();
  const getRobots = async () => {
    setLoading(true);
    getRobotList().then((res) => {
      if (res && res.data) setRobots(res.data);
      navigate("/blank");
      setLoading(false);
    });
  };
  useEffect(() => {
    getRobots();
  }, []);
  useEffect(() => {
    setRobotOption(
      robots.map((item) => {
        return {
          value: item.id,
          label: item.nickname,
        };
      })
    );
    setValue(robots[0]?.id);
  }, [robots]);

  return [robotOption, getRobots];
};

export const useSub = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    unsubscribe("auth");
    unsubscribe("error");
    unsubscribe("success");
    subscribe("auth", () => {
      navigate("/login");
    });
    subscribe("error", (type, message) => {
      messageApi.warning(message);
    });
    subscribe("success", (type, message) => {
      messageApi.success(message);
    });
  }, []);
  return [contextHolder, messageApi];
};

export const useAnimation = (chats) => {
  useEffect(() => {
    const chatRefs = document.querySelectorAll(".card");
    const lastElem = chatRefs[chatRefs.length - 1];
    lastElem
      ?.animate(
        {
          marginTop: ["60px", "10px"],
          opacity: [0, 1],
        },
        {
          duration: 300,
          easing: "linear",
        }
      )
      .finished.then(() => {
        chatRefs.forEach((item, index) => {
          if (index == chatRefs.length - 1) {
            item.animate(
              {
                marginTop: ["10px", `0px`, `10px`],
              },
              {
                duration: 300,
                easing: "linear",
              }
            );
          }
        });
      });
  }, [chats]);
};
