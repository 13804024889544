import { Form, Spin, message } from "antd";
import { addUser, getRobotList, loginUser } from "../../api/user";
import { Fragment, useState } from "react";
import { publish } from "pubsub-js";
import { removeUser, setUser } from "../../localStorage/user";
import { getChats } from "../../api/chat";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../localStorage/token";

export const useAnimation = () => {
  const registeAnimation = () => {
    const loginCard = document.querySelector(".login-card");
    const registeCard = document.querySelector(".registe-card");
    loginCard
      .animate(
        [
          {
            transform: "rotateY(0deg)",
          },
          {
            transform: "rotateY(90deg)",
          },
        ],
        {
          duration: 600,
          fill: "forwards",
          easing: "ease",
        }
      )
      .finished.then(() => {
        loginCard.style.display = "none";
        registeCard.style.display = "block";
        registeCard
          .animate(
            [
              {
                transform: "rotateY(270deg)",
              },
              {
                transform: "rotateY(360deg)",
              },
            ],
            {
              duration: 600,
              fill: "forwards",
              easing: "ease",
            }
          )
          .finished.then(() => {});
      });
  };
  const loginAnmation = () => {
    const loginCard = document.querySelector(".login-card");
    const registeCard = document.querySelector(".registe-card");
    registeCard
      .animate(
        [
          {
            transform: "rotateY(0deg)",
          },
          {
            transform: "rotateY(90deg)",
          },
        ],
        {
          duration: 600,
          fill: "forwards",
          easing: "ease",
        }
      )
      .finished.then(() => {
        registeCard.style.display = "none";
        loginCard.style.display = "block";
        loginCard
          .animate(
            [
              {
                transform: "rotateY(270deg)",
              },
              {
                transform: "rotateY(360deg)",
              },
            ],
            {
              duration: 600,
              fill: "forwards",
              easing: "ease",
            }
          )
          .finished.then(() => {});
      });
  };
  return [registeAnimation, loginAnmation];
};
export const useForm = (
  changeLogin,
  setLoading,
  setRobots,
  setChats,
  setChat
) => {
  const navigate = useNavigate();
  const registe = async (form) => {
    if (form.okPassword != form.password) {
      console.log(11);
      publish("error", "两次密码不一致");
      return;
    }
    setLoading(true);
    const res = await addUser({
      username: form.username,
      password: form.password,
    });
    console.log(res);
    if (res && res.code == 200) {
      publish("success", "注册成功");
      changeLogin();
    }
    setLoading(false);
  };
  const login = async (form) => {
    setLoading(true);
    const loginRes = await loginUser(form);
    if (loginRes && loginRes.code == 200) {
      setToken(loginRes.data);
      publish("success", "登录成功");
      if (form.remember) {
        setUser(form);
      } else {
        removeUser();
      }
      const robotRes = await getRobotList();
      if (robotRes && robotRes.code == 200) {
        setRobots(robotRes.data);
        if (robotRes.data.length > 0) {
          const res = await getChats(robotRes.data[0].id);
          if (res && res.code == 200) {
            setChats(res.data);
            setChat(res.data[0]);
          }
        }
      }
    }
    setLoading(false);
  };

  return [registe, login];
};
