import React, { Fragment } from "react";

import {
  unstable_HistoryRouter as HistoryRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { subscribe } from "pubsub-js";
import router from "./router";

export default function App() {
  return (
    <Fragment>
      <RouterProvider router={router}></RouterProvider>
    </Fragment>
  );
}
