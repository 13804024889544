import { styled } from "styled-components";

export const LoadingWrapper = styled.div`
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  backdrop-filter: blur(10px);
  background: ${(p) => p.theme.backgroudColor};
`;
